// TODO: abstract this to config
import RileyWelch from './static/images/reviews/Riley_Welch_92Social_Owner.jpg'
import i4Solutions from './static/images/reviews/i4Solutions.jpg'

export const API_URL = 'https://localhost:3000/api'

export const AGENCY_VISTA_REVIEWS = [
  {
    title: 'Feels like a true partnership',
    description: `As an entrepreneur, Agency Vista is a huge help for my business to grow and connect with other businesses. It's a challenge to create strong business relationships when you're starting out in the industry. Agency Vista helps you build and maintain the professional relationships you need.`,
    author: {
      name: 'Riley Welch',
      position: '92Social, Owner',
      logo: RileyWelch,
    },
  },
  {
    title: 'AgencyVista is a refreshing approach to researching a potential marketing partner',
    description: `Their rich agency profiles make it easy for potential clients to research our services and the verified client data means there is full transparency about the results we have achieved. As an agency, we are glad to see platforms like AgencyVista bring more transparency to an industry that is not known for being the most trustworthy at times.`,
    author: {
      name: '',
      position: 'i4 Solutions',
      logo: i4Solutions,
    },
  },
]

export const AGENCY_VISTA_REVIEW = AGENCY_VISTA_REVIEWS[0]

export const VIDEOS = [
  {
    type: 'business',
    title: 'Find Marketing Agencies',
    slug: 'find-marketing-agencies',
    description:
      'Agency Vista is a new way for brands to find and easily connect with digital marketing agencies.  No more relying on Google, word-of-mouth, or chance encounters to find an agency that’s right for your business. Sit back and let Agency Vista’s search engine do all of the legwork for you by matching your marketing requirements with the skills and performance of over 39,000 marketing agencies that are part of the Agency Vista network.',
    link: 'https://www.youtube.com/embed/d-3_j0wRLkw',
  },
  {
    type: 'agency',
    title: 'Discover How Your Client Information Is Shown',
    slug: 'client-information',
    description: `Allow potential clients to distinguish your area of focus and learn more about your unique expertise. Find out how you can control what information is public and how it gets verified to produce more qualified leads for your agency!`,
    link: 'https://www.youtube.com/embed/97xYccEXXr0',
  },
  {
    type: 'agency',
    title: 'Get More Leads Through Onboarding Your Clients',
    slug: 'onboard-clients',
    description: `Give businesses the confidence of knowing that they are working with a reliable agency by providing social proof to profile visitors. Your client information acts as a seed audience for our advanced search to match your profile with similar businesses.`,
    link: 'https://www.youtube.com/embed/dqdsY7AcE2Q',
  },
  {
    type: 'agency',
    title: 'Verify Your Clients To Foster Credibility',
    slug: 'verify-clients',
    description: `This is another step in our verification that gives businesses the confidence that they’re working with a reliable agency.  When you authenticate this information, you will be increasing the number of searches your agency profile can appear.`,
    link: 'https://www.youtube.com/embed/2mfNhu5uZPU',
  },
  {
    type: 'agency',
    title: 'Earn more trust and credibility through verification',
    slug: 'get-verified',
    description: `The Agency Vista badge is awarded to companies that meet the highest standards of performance and service. If you’ve got a badge, it tells your customers and potential clients you’re among the best at what you do.`,
    link: 'https://www.youtube.com/embed/ruu_Mr-kn8E',
  },
  {
    type: 'agency',
    title: 'Connect Your Marketing Partner Badges',
    slug: 'partner-badges',
    description: `Increase your profile visibility by connecting your partner badges and unlock new high-traffic placements on Agency Vista.`,
    link: 'https://www.youtube.com/embed/qrUJxsanCOo',
  },
  {
    type: 'agency',
    title: 'Hire Top Talent Through Your Profile',
    slug: 'post-jobs',
    description: `Posting your job on Agency Vista allows you to reach the <a color="primary" href="/trust" target="_blank" rel="noreferrer noopener">world's largest network of marketers</a> with agency expertise to connect your job with qualified candidates. You can post your job opening in minutes, track applicants, and can promote your opening to increase engagement.`,
    link: 'https://www.youtube.com/embed/UtD5oJKqnMg',
  },
]
