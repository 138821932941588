import React, { useState } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { Formik } from 'formik'
import * as Yup from 'yup'
import App from 'App'
import { space, fontSizes, colors, COLOR_CONSTANTS, radius } from 'theme'
import { API_URL } from 'consts'
import SEO from 'components/SEO'
import { Box, Flex } from 'components/Layout'
import { H1, Text } from 'components/Typography'
import Input from 'components/Input'
import Button from 'components/Button'
import Footer from 'components/Footer'

const HI_EMAIL_ADDRESS = 'hi@agencyvista.com'

const StyledLink = styled.a`
  font-size: ${fontSizes.m};
  text-decoration: none;
  color: ${colors.primary};
  opacity: 0.8;
  &:visited {
    color: initial;
  }
`

const StyledForm = styled.form`
  margin-top: ${space.l};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  width: 100%;
`

const StyledTextArea = styled.textarea`
  padding: ${space.s};
  border-radius: ${radius.s};
  color: ${colors.primaryText};
  font-size: ${fontSizes.s};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  outline: none;
  resize: none;
  ::placeholder {
    color: ${COLOR_CONSTANTS.GLACIER_PEARL};
    font-size: ${fontSizes.s};
  }
`

const { NAME, EMAIL, MESSAGE } = { NAME: 'name', EMAIL: 'email', MESSAGE: 'message' }

const ContactValidationSchema = Yup.object().shape({
  [NAME]: Yup.string().required('Name is required'),
  [EMAIL]: Yup.string()
    .required('Email is required')
    .email('Must be a valid email address'),
  [MESSAGE]: Yup.string().required('Message cannot be empty'),
})

const Contact = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const handleSubmitForm = async (values) => {
    try {
      setIsSubmitting(true)
      const res = await fetch(`${process.env.GATSBY_API_URL}/contact`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
      })
      if (res.status === 404) {
        navigate('/404')
      } else {
        navigate('/received')
      }
    } catch (error) {
      navigate('/500')
    } finally {
      setIsSubmitting(false)
    }
  }
  return (
    <App>
      <SEO
        title="Contact"
        description="Discover the perfect agency for your business. Search and connect with thousands of verified social media agencies."
        path="/contact/"
      />
      <Flex mt="xl" px="m" alignItems="center" flexDirection="column" textAlign="center">
        <H1>Questions? Feedback?</H1>
        <H1>Get in touch!</H1>
        <Flex mt="m" flexWrap="wrap">
          <Text opacity={0.8} color="secondaryText" fontSize="l">
            Write us an email at&nbsp;
            <StyledLink href={`mailto:${HI_EMAIL_ADDRESS}`}>hi@agencyvista.com</StyledLink>
            &nbsp;or use the contact form below
          </Text>
        </Flex>
        <Formik
          initialValues={{
            [NAME]: '',
            [EMAIL]: '',
            [MESSAGE]: '',
          }}
          onSubmit={handleSubmitForm}
          validationSchema={ContactValidationSchema}
        >
          {({ values, handleChange, handleSubmit, touched, errors }) => (
            <StyledForm onSubmit={handleSubmit}>
              <Input
                placeholder="Name"
                values={values[NAME]}
                onChange={handleChange(NAME)}
                error={errors[NAME] && touched[NAME] && errors[NAME]}
                width="100%"
              />
              <Input
                placeholder="Email"
                values={values[EMAIL]}
                onChange={handleChange(EMAIL)}
                error={errors[EMAIL] && touched[EMAIL] && errors[EMAIL]}
                width="100%"
              />
              <StyledTextArea placeholder="Message" value={values[MESSAGE]} onChange={handleChange(MESSAGE)} rows="6" />
              {errors[MESSAGE] && touched[MESSAGE] && (
                <Text textAlign="left" color="primary">
                  {errors[MESSAGE]}
                </Text>
              )}
              <Flex mt="m" alignSelf="flex-end">
                <Button.Primary type="submit" onClick={handleSubmit} isDisabled={isSubmitting}>
                  <Text>{isSubmitting ? 'Sending a message' : 'Send a message'}</Text>
                </Button.Primary>
              </Flex>
            </StyledForm>
          )}
        </Formik>
      </Flex>
      <Box mt="xxl">
        <Footer />
      </Box>
    </App>
  )
}

export default Contact
